import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useOutsideClick } from '../../utils/hooks/useOutsideClick';
import { sendEmail } from '../../utils/sendEmail';
import ModalForm from './ModalForm/ModalForm';
import Privacy from '../Privacy/Privacy';
import styles from './styles.module.scss';

const Modal = ({ isOpen, onClose }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const onHandleSubmit = async (userData) => {
    setIsFetching(true);
    const response = await sendEmail(userData);
    console.log(response);
    if (response === 201) {
      setIsFetching(false);
      alert('Письмо отправилось успешно!');
      userData.firstName = '';
      userData.text = '';
      userData.email = '';
    } else {
      setIsFetching(false);
      alert('Не удалось отправить!');
    }
  };
  const { rootEl } = useOutsideClick(onClose);
  if (!isOpen) return null;
  return createPortal(
    <>
      <div className={styles['modal-overlay']} ref={rootEl} />
      <div className={styles.modal}>
        <div className={styles['modal-container']}>
          <div onClick={onClose} className={styles['btn-close']}>
            <img src="/pic/close-btn.svg" alt="кнопка закрыть" />
          </div>
          <div className={styles['modal-wrapper']}>
            <h2 className={styles['modal-title']}>Обратная связь</h2>
            <ModalForm handleSubmit={onHandleSubmit} isFetching={isFetching} />
            <div className={styles['modal-info']}>
              <p>
                Заказывая звонок, вы соглашаетесь
                с нашей
                {' '}
                <span onClick={() => setIsPrivacyOpen(true)}>
                  Политикой конфиденциальности.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Privacy isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} />
    </>,
    document.getElementById('modal'),
  );
};
export default Modal;
