import Container from '../Container/Container';
import styles from './styles.module.scss';

const content = [
  {
    id: 1,
    title: 'Исследования',
    description: 'Во-первых, мы постоянно совершенствуем продукты \n'
        + 'IVA NATURA. Мы используем передовые технологии'
        + 'для производства продуктов по уходу за вашей кожей и волосами.',
    img: '/pic/production/production-img_1.png',
  },
  {
    id: 2,
    title: 'Ингридиенты',
    description: 'На втором этапе мы работаем над тем, какими должны быть активные '
        + 'ингредиенты продуктов, которые мы определили. Наши усилия '
        + 'в области исследований и разработок постоянно работают \n'
        + 'над тем, чтобы производить лучшие продукты \n'
        + 'для вас.',
    img: '/pic/production/production-img_2.png',
  },
  {
    id: 3,
    title: 'Формула',
    description: 'Каждый из продуктов IVA NATURA содержит множество '
        + 'растительных компонентов с одинаковой целью. По этой причине в каждом продукте \n'
        + 'IVA NATURA эти растения объединяются и помогают '
        + 'создать свою уникальную формулу, еще больше усиливая свойства продукта.',
    img: '/pic/production/production-img_3.png',
  },
  {
    id: 4,
    title: 'Технология',
    description: 'Продукты IVA NATURA используют технологию активных липосом. Липосомная технология гарантирует, что компоненты растений '
        + 'в продуктах используются наиболее эффективным образом',
    img: '/pic/production/production-img_1.png',
  },
];

const ProductionBlock = ({ block }) => (
  <div className={`${styles['production-block']} ${(block.id % 2 === 0) ? `${styles.even}` : `${styles.odd}`}`}>
    <div className={styles['production-block-inner']}>
      <div className={styles['production-block-descr']}>
        <div className={styles.title}>
          <span>
            0
            {block.id}
            {' '}
          </span>
          {block.title}
        </div>
        <div className={styles['product-text']}>{block.description}</div>
      </div>
      <div className={styles['production-block-image']}>
        <img src={block.img} alt="product" />
      </div>
    </div>
  </div>
);

const Production = () => (
  <section className={styles.production}>
    <Container>
      <div className={styles['production-wrapper']}>
        {content?.map((block) => <ProductionBlock key={block.id} block={block} />)}
      </div>
    </Container>
  </section>
);

export default Production;
