import Container from '../Container/Container';
import styles from './styles.module.scss';

const Slogan = ({ slogan, children }) => {
  const { pic1, pic2 } = slogan;
  return (
    <section className={styles.slogan}>
      <Container>
        <div className={styles['slogan-wrapper']}>
          {children}
          <img className={styles['slogan-pic-right']} src={pic1} alt="slogan-pic" />
          <img className={styles['slogan-pic-left']} src={pic2} alt="slogan-pic" />
        </div>
      </Container>
    </section>
  );
};

export default Slogan;
