import Container from '../../Container/Container';
import styles from './styles.module.scss';

const BrandLine = ({ image }) => (
  <section className={styles.brandLine}>
    <Container>
      <div className={styles['brandLine-wrapper']}>
        <img src={image} alt="brand-logos" />
      </div>
    </Container>
  </section>
);
export default BrandLine;
