import Container from '../../Container/Container';
import MultiSlider from '../../MultiSlider/MultiSlider';
import styles from './styles.module.scss';

const ProductCardSlider = ({ cards }) => (
  <div className={styles['product-card-slider']}>
    <Container>
      <h2 className={styles.title}>Похожие товары</h2>
      <MultiSlider cards={cards} />
    </Container>
  </div>
);
export default ProductCardSlider;
