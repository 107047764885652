import styles from './styles.module.scss';

const PurposeContent = () => (
  <div className={styles['purpose-content']}>
    <h6 className={styles.subtitle}>IVA NATURA</h6>
    <div className={styles['purpose-text']}>
      <p>
        Рецептуры, созданные с использованием
        чудодейственных растительных экстрактов
        и нетронутых природных ресурсов,
        воплощаются в жизнь в нашей лаборатории, которая работает
        без остановки.
      </p>
      <p>
        Страсть, природа и наука. Faber Kimya
      </p>
    </div>
  </div>
);
export default PurposeContent;
