export const subcategoryList = {
  m_water: 'Мицелярная вода',
  cream: 'Крем',
  mask: 'Маска',
  oil: 'Масло',
  scrub: 'Скраб',
  sh_gel: 'Гель для душа',
  shampoo: 'Шампунь',
  lotion: 'Лосьон',
  cond: 'Кондиционер',
  tonic: 'Тоник',
};
