import React from 'react';

// import nope from "../../common/img/nope.svg"

import styles from './NotFound.module.scss';

const NotFound = () => (
  <div className={styles.nope}>
    {/* <img className={styles.img} src={nope} alt="робот"/> */}
    <p className={styles.text}>
      Упс… Мы не можем найти то, что Вы ищете
    </p>
  </div>
);

export default NotFound;
