import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return { width };
};
export const useWindowDimension = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
};
