import React from 'react';
import Slider from 'react-slick';
/* eslint-disable import/no-unresolved */
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import './styles.scss';
import PurposeSlide from './PurposeSlide/PurposeSlide';

/* eslint-disable react/jsx-props-no-spreading */

const PurposeSlider = React.forwardRef(({ images }, ref) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="purpose-slider">
      <div className="purpose-slider-wrapper">
        <Slider
          className="purpose-slider-inner"
          {...settings}
          ref={ref}
        >
          {images?.map((image) => (
            <PurposeSlide
              key={image.id}
              image={image.img}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
});
export default PurposeSlider;
