import Container from '../../Container/Container';
import FbackSlider from '../../FbackSlider/FbackSlider';
import styles from './styles.module.scss';

const ProductFeedback = ({ reviewCard }) => (
  <div className={styles['product-feedback']}>
    <Container>
      <h2 className={styles.title}>Отзывы о товаре</h2>
      <FbackSlider reviewCard={reviewCard} />
    </Container>
  </div>
);
export default ProductFeedback;
