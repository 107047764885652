import React, { createRef } from 'react';
import PurposeContent from './PurposeContent/PurposeContent';
import PurposeSlider from './PurposeSlider/PurposeSlider';
import PurposeTitle from './PurposeTitle/PurposeTitle';
import PurposeArrows from './PurposeArrows/PurposeArrows';
/* eslint-disable import/no-unresolved */
import styles from './styles.module.scss';
import Container from '../Container/Container';

const Purpose = ({ images }) => {
  const purposeSliderNode = createRef();

  const gotoNext = () => {
    purposeSliderNode.current.slickNext();
  };

  const gotoPrev = () => {
    purposeSliderNode.current.slickPrev();
  };
  return (
    <section className={styles.purpose}>
      <Container>
        <PurposeTitle />
        <div className={styles['purpose-wrapper']}>
          <PurposeSlider ref={purposeSliderNode} images={images} />
          <PurposeContent />
          <PurposeArrows gotoNext={gotoNext} gotoPrev={gotoPrev} />
        </div>
      </Container>
    </section>
  );
};

export default Purpose;
