import { Link } from 'react-router-dom';
import Container from '../Container/Container';
import styles from './styles.module.scss';

const ProductOne = () => {
  const images = {
    img: '/pic/product-one/product-one_img.png',
  };
  return (
    <section className={styles.productOne}>
      <Container>
        <div className={styles['productOne-wrapper']}>
          <div className={styles['productOne-image']}>
            <img src={images.img} alt="product" />
          </div>
          <div className={styles['productOne-descr']}>
            <div className={styles['productOne-inner']}>
              <h6 className={styles.subtitle}>БОГАТЫЕ ЭКСТРАКТЫ ТИМЬЯНА</h6>
              <h2 className={styles.title}>
                КОНДИЦИОНЕР С ТИМЬЯНОМ
                {' '}
                <span>IVA NATURA</span>
                ,
                ПРИДАЮЩИЙ ОБЪЕМ
              </h2>
              <div className={styles['product-text']}>
                <p>
                  Придайте волосам необходимую мягкость и шелковистость с помощью кондиционера
                  IVA NATURA. Позвольте ему питать пряди ваших волос от корней до кончиков
                  богатыми экстрактами тимьяна.
                </p>
              </div>
              <div className={styles['btn-main']}>
                <Link to="/hair">Подробнее</Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default ProductOne;
