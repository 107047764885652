import styles from './styles.module.scss';

const RunLines = ({ runText }) => (
  <div className={styles.marquee}>
    <p className={styles['marquee-line']}>{runText}</p>
    <p className={styles['marquee-line-2']}>{runText}</p>
  </div>
);

export default RunLines;
