import Container from '../../Container/Container';
import styles from './styles.module.scss';

/* eslint camelcase: ["error", {"properties": "never"}] */

const ProductInfo = (
  {
    prodInfo,
  },
) => (
  <div className={styles['product-card-about']}>
    <Container>
      <h2 className={`${styles.title}`}>Информация о продукте</h2>
      <div className={styles['product-card-about-descr']}>
        {prodInfo?.description && (
          <div className={styles['product-card-about-text']}>
            <h6 className={styles['product-card-about-subtitle']}>
              О продукте
            </h6>
            <p>
              {prodInfo.description}
            </p>
          </div>
        )}
        {prodInfo?.suggested_use && (
          <div className={styles['product-card-about-text']}>
            <h6 className={styles['product-card-about-subtitle']}>
              Рекомендации по использованию
            </h6>
            <p>
              {prodInfo.suggested_use}
            </p>
          </div>
        )}
        {prodInfo?.other_ingredients && (
          <div className={styles['product-card-about-text']}>
            <h6 className={styles['product-card-about-subtitle']}>
              Другие ингридиенты
            </h6>
            <p>
              {prodInfo.other_ingredients}
            </p>
          </div>
        )}
        {prodInfo?.warnings && (
          <div className={styles['product-card-about-text']}>
            <h6 className={styles['product-card-about-subtitle']}>
              Остережения
            </h6>
            <p>
              {prodInfo.warnings}
            </p>
          </div>
        )}
        {prodInfo?.disclaimer && (
          <div className={styles['product-card-about-text']}>
            <h6 className={styles['product-card-about-subtitle']}>
              Отказ
            </h6>
            <p>
              {prodInfo.disclaimer}
            </p>
          </div>
        )}
      </div>
    </Container>
  </div>
);
export default ProductInfo;
