import styles from './styles.module.scss';
import { ReactComponent as StarImg } from '../../pic/grade.svg';

const Star = ({ status }) => (
  <StarImg
    src="../pic/product-page/icons/grade.svg"
    alt="rate"
    className={`${styles.star} ${styles[status]}`}
  />
);
export default Star;
