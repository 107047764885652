import styles from './styles.module.scss';

const ProductTwoContent = () => (
  <div className={styles.content}>
    <div className={styles['content-inner']}>
      <h6 className={styles.subtitle}>IVA NATURA</h6>
      <h2 className={styles.title}>
        АКТИВНЫЙ ДНЕВНОЙ КРЕМ
      </h2>
      <div className={styles['product-text']}>
        <p>
          Дневной крем IVA NATURA помогает вашей коже приобрести
          шелковистый вид, обеспечивая ежедневное увлажнение,
          в котором она нуждается. Благодаря формуле крема,
          обогащенной зародышами пшеницы и экстрактом пшеницы,
          вы почувствуете мягкость на своей коже с первого
          прикосновения.
        </p>
      </div>
    </div>
  </div>
);
export default ProductTwoContent;
