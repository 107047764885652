import styles from './styles.module.scss';
import ProductTwoContent from './ProductTwoContent/ProductTwoContent';

const ProductTwo = () => (

  <section
    className={styles.productTwo}
  >
    <ProductTwoContent />
    <div
      className={styles['product-two-image']}
      style={{
        backgroundImage: "url('/pic/product-two/product-two-bg.jpg')",
        backgroundSize: 'cover',
      }}
    />
  </section>
);
export default ProductTwo;
