import { configureStore } from '@reduxjs/toolkit';
import cardsReducer from './cardSlice/cardsSlice';
import seoReducer from './seo/seoSlice';
import categoriesReducer from './categories/categoriesSlice';
import reviewsReducer from './reviews/reviewsSlice';

const store = configureStore({
  reducer: {
    cards: cardsReducer,
    seo: seoReducer,
    categories: categoriesReducer,
    reviews: reviewsReducer,
  },
});
export default store;
