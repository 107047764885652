import { Link } from 'react-router-dom';
import SmallCard from '../../generic/SmallCard/SmallCard';
import styles from './styles.module.scss';

const Dropdown = ({ cards }) => (
  <div className={styles.dropdown}>
    <div className={styles['dropdown-menu']}>
      <nav className={styles['mobile-menu']}>
        <ul className={styles['mobile-menu-down']}>
          <li>
            <Link className={styles['header-nav-link']} to="/face">Лицо</Link>
          </li>
          <li>
            <Link className={styles['header-nav-link']} to="/hair">Волосы</Link>
          </li>
          <li>
            <Link className={styles['header-nav-link']} to="/body">Тело</Link>
          </li>
          <li>
            <Link className={styles['header-nav-link']} to="/tan">Загар</Link>
          </li>
          <li>
            <Link className={styles['header-nav-link']} to="/baby">Дети</Link>
          </li>
        </ul>
      </nav>
    </div>
    <div className={styles['cards-wrapper']}>
      {cards.length > 0 ? cards.map((card) => (
        <SmallCard
          key={card.id}
          cardId={card.id}
          img={card.pic}
          title={card.title}
        />
      )) : <span>Ничего не найдено</span>}
    </div>
  </div>
);
export default Dropdown;
