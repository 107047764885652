import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import SEO from '../../components/SEO/SEO';
import Production from '../../components/Production/Production';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Slogan from '../../components/Slogan/Slogan';
import RunLines from '../../components/RunLines/RunLines';
import styles from './style.module.scss';
import Purpose from '../../components/Purpose/Purpose';
import ProductOne from '../../components/ProductOne/ProductOne';
import ProductTwo from '../../components/ProductTwo/ProductTwo';
import Collage from '../../components/Collage/Collage';
import Contacts from '../../components/Contacts/Contacts';
import News from '../../components/News/News';
import CardsWrapper from '../../components/generic/Card/CardsWrapper/CardsWrapper';
import { useWindowDimension } from '../../utils/hooks/useWindowDimensions';

const HeaderSlider = React.lazy(() => import('../../components/HeaderSlider/HeaderSlider'));

const slogan = {
  text: 'READY TO CREATE A UNIQUE EXPERIENCE?\n'
        + 'IT`S TIME TO BUILD SOMETHING AMAZING TOGETHER!',
  pic1: '/pic/slogan/slogan-img_1.png',
  pic2: '/pic/slogan/slogan-img_2.png',
};

const runText = 'IVA NATURA ORGANIC COSMETIC IVA NATURA ORGANIC COSMETIC '
    + 'IVA NATURA ORGANIC COSMETIC IVA NATURA '
    + 'ORGANIC COSMETIC IVA NATURA ORGANIC COSMETIC IVA NATURA ORGANIC COSMETIC';
const news = [
  {
    id: 1,
    pics: {
      desktop: './pic/news/news-img_1-1920w.webp',
      laptop: './pic/news/news-img_1-1280w.webp',
      tablet: './pic/news/news-img_1-768w.webp',
      mobile: './pic/news/news-img_1-480w.webp',
      iphone: './pic/news/news-img_1-375w.webp',

    },
    title: 'То, чем мы не можем насытиться- это сертифицированный органический уход!',
  },
  {
    id: 2,
    pics: {
      desktop: './pic/news/news-img_2-1920w.webp',
      laptop: './pic/news/news-img_2-1280w.webp',
      tablet: './pic/news/news-img_2-768w.webp',
      mobile: './pic/news/news-img_2-480w.webp',
      iphone: './pic/news/news-img_2-375w.webp',

    },
    title: 'Что мы можем сделать для обесцвечивания солнечных пятен на нашей коже?',
  },
  {
    id: 3,
    pics: {
      desktop: './pic/news/news-img_3-1920w.webp',
      laptop: './pic/news/news-img_3-1280w.webp',
      tablet: './pic/news/news-img_3-768w.webp',
      mobile: './pic/news/news-img_3-480w.webp',
      iphone: './pic/news/news-img_3-375w.webp',

    },
    title: 'Почему наши волосы выпадают?',
  },
];
const purpose = [
  {
    id: 1,
    img: '/pic/purpose-slider/purpose-slider_1.jpeg',
  },
  {
    id: 2,
    img: '/pic/purpose-slider/purpose-slider_2.jpeg',
  },
  {
    id: 3,
    img: '/pic/purpose-slider/purpose-slider_3.jpeg',
  },
  {
    id: 4,
    img: '/pic/purpose-slider/purpose-slider_4.jpeg',
  },
  {
    id: 5,
    img: '/pic/purpose-slider/purpose-slider_5.jpeg',
  },
  {
    id: 6,
    img: '/pic/purpose-slider/purpose-slider_6.jpeg',
  },
];

const MainPage = () => {
  const { width } = useWindowDimension();
  const { cards } = useSelector((state) => state.cards);

  return (
    <div className={styles['main-page']}>
      <SEO mark="main" />
      <Header cards={cards.slice(0, 4)} />
      <Suspense fallback={<div>Loading...</div>}>
        <HeaderSlider />
      </Suspense>
      <Slogan slogan={slogan}>
        <h1 className={styles['slogan-text']}>{slogan.text}</h1>
      </Slogan>
      <CardsWrapper cards={width <= 1280 ? cards.slice(0, 3) : cards.slice(0, 4)} />
      <RunLines runText={runText} />
      <ProductOne />
      <ProductTwo />
      <Collage />
      <Production />
      <Purpose images={purpose} />
      <News news={news} />
      <Contacts />
      <Footer />
    </div>
  );
};

export default MainPage;
