import Container from '../../Container/Container';
import ProdCardThumb from '../../ProdCardThumb/ProdCardThumb';
import ProductDescr from '../ProductDescr/ProductDescr';
import ProductTitle from './ProductTitle/ProductTitle';
import styles from './styles.module.scss';

const ProductHead = ({
  title, sliderPics, icons, description, modalControl, vendorcode, volume,
}) => (
  <div className={styles['product-card-head']}>
    <Container>
      <div className={styles['head-wrapper']}>
        <ProductTitle title={title} />
        <ProdCardThumb images={sliderPics} />
        <ProductDescr
          icons={icons}
          description={description}
          modalControl={modalControl}
          vendorcode={vendorcode}
          volume={volume}
        />
      </div>
    </Container>
  </div>
);
export default ProductHead;
