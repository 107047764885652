import axios from "axios";
import uri from "./api/uri";
/* eslint quotes: ["error", "double"] */
/* eslint quote-props: ["error", "consistent"] */
/* eslint-env es6 */

export const sendEmail = async (userData) => {
  const receivers = ["abazaliev.ar@zerolab.tech"];
  const { firstName, text, email } = userData;
  const response = await axios.post(uri.emails, {
    "email_from": "test@ivanatura.net",
    "name_from": firstName,
    "mail_to": receivers[0],
    "subject": email,
    "text": text,
  }, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.status).catch((error) => error);
  return response;
};
