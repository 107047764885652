import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import ProductHead from './ProductHead/ProductHead';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductFeedback from './ProductFeedback/ProductFeedback';
import ProductCardSlider from './ProductCardSlider/ProductCardSlider';
import { getReviews } from '../../redux/store/reviews/reviewsServices';
import uri from '../../utils/api/uri';

/* eslint camelcase: ["error",
 {"properties": "never", ignoreDestructuring: true, allow: ["^UNSAFE_"]}] */

const productInfo = {
  title: 'Информация о продукте',
  textBlock1: {
    title: 'Антивозрастной антивозрастной крем IVA NATURA, \n'
    + 'содержащий органическую гиалуроновую кислоту, \n'
    + 'с экстрактом цветков бессмертной травы',
  },

};

const ProductCard = ({ product }) => {
  const {
    title,
    about,
    description,
    pic_slider,
    disclaimer,
    other_ingredients,
    suggested_use,
    warnings,
    category,
    vendor_code,
    volume,
  } = product;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviewsList, setReviewsList] = useState([]);
  const dispatch = useDispatch();
  const cards = useSelector((state) => (
    state.cards.cards.filter(
      (card) => card.category === category,
    )
  ));
  const { reviews } = useSelector((state) => state.reviews);
  const reviewCard = reviewsList.filter((review) => review.card === product.id);

  useEffect(() => {
    if (!reviews) {
      dispatch(getReviews(uri.reviews));
    } else {
      setReviewsList([...reviews]);
    }
  }, [reviews]);

  const icons = {
    icon1: '/pic/social/instagram.svg',
    icon2: '/pic/social/vk.svg',
    icon3: '/pic/social/dzen.svg',
  };
  const prodInfo = {
    about,
    description,
    disclaimer,
    other_ingredients,
    suggested_use,
    warnings,
  };
  console.log(product);
  return (
    <section className={styles['product-card-section']}>
      <ProductHead
        modalControl={[isModalOpen, setIsModalOpen]}
        title={title}
        // eslint-disable-next-line camelcase
        sliderPics={pic_slider}
        icons={icons}
        description={description}
        // eslint-disable-next-line camelcase
        vendorcode={vendor_code}
        volume={volume}
      />
      <ProductInfo prodInfo={prodInfo} title={productInfo.title} />
      {reviewCard.length > 0 ? <ProductFeedback reviewCard={reviewCard} /> : null}
      <ProductCardSlider cards={cards} />
    </section>
  );
};

export default ProductCard;
