import { useEffect, useState } from 'react';
import Star from '../Star/Star';
import styles from './styles.module.scss';

const RatingStars = ({ rating }) => {
  const activeStar = <Star status="active" />;
  const deactiveStar = <Star status="deactive" />;
  const [stars, setStars] = useState([]);

  useEffect(() => {
    if (stars.length < 5) {
      if (stars.length <= rating - 1) {
        setStars((prev) => [...prev, activeStar]);
      }
      if (stars.length > rating - 1) {
        setStars((prev) => [...prev, deactiveStar]);
      }
    }
  }, [stars]);

  return (
    <ul className={styles.rating}>
      {stars.map((star, i) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={styles.item}
        >
          {star}
        </li>
      ))}
    </ul>
  );
};
export default RatingStars;
