import styles from './styles.module.scss';

const PurposeArrows = ({ gotoPrev, gotoNext }) => (
  <div className={styles['purpose-arrows']}>
    <div className={styles['purpose-arrows__inner']}>
      <div className={`${styles['purpose-arrow']} ${styles['purpose-prev']}`} onClick={() => gotoPrev()}>
        <img src="/pic/arrows/card-slider-arrows/arrow-left.png" alt="prevArrow" />
      </div>
      <div className={`${styles['purpose-arrow']} ${styles['purpose-next']}`} onClick={() => gotoNext()}>
        <img src="/pic/arrows/card-slider-arrows/arrow-right.png" alt="nextArrow" />
      </div>
    </div>
  </div>
);
export default PurposeArrows;
