import { NavLink } from 'react-router-dom';
import styles from '../styles.module.scss';

const MultiSlide = ({ image, prodName, cardId }) => (

  <div className={styles['custom-slide']}>
    <NavLink to={`/about/${cardId}`}>
      <div className={styles['custom-slide-image']}>
        <img src={image} alt="slide" />
      </div>
    </NavLink>
    <span className={styles['custom-slide-name']}>{prodName}</span>
  </div>

);

export default MultiSlide;
