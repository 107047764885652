import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import CareProducts from '../../components/CareComponents/CareProducts/CareProducts';
import styles from './styles.module.scss';
import CareIntro from '../../components/CareComponents/CareIntro/CareIntro';
import BrandLine from '../../components/CareComponents/BrandLine/BrandLine';
import SEO from '../../components/SEO/SEO';
import { getCards } from '../../redux/store/cardSlice/cardsServices';
import { getCategories } from '../../redux/store/categories/categoriesService';
import uri from '../../utils/api/uri';
import Certificates from '../../components/Certificates/Certificates';

const brandLine = {
  img: './pic/brandLine/bg.png',
};

const FacePage = () => {
  const dispatch = useDispatch();
  const { cards } = useSelector((state) => state.cards);
  const categories = useSelector((state) => state.categories?.categories.filter((category) => category.name === 'face'));
  const [cardList, setCardList] = useState([]);
  let subcategories = [];
  if (cards) {
    const CATEGORIES = cards.filter((el) => el.category === 'face');
    const SUBCATEGORIES = CATEGORIES.map((el) => el.subcategory);
    const FILTERED_SUBCATS = SUBCATEGORIES.filter((el, ind) => SUBCATEGORIES.indexOf(el) === ind);
    subcategories = FILTERED_SUBCATS;
  }

  const face = cardList.filter((card) => card.category === 'face');
  const showSubcategory = (subcat) => {
    const filteredCards = cards.filter((card) => card.subcategory === subcat);
    setCardList(filteredCards);
  };
  useEffect(() => {
    if (!cards) {
      dispatch(getCards());
    }
    console.log('render');
    dispatch(getCategories(uri.categories));
    setCardList([...cards]);
  }, [cards]);
  return (
    <div className={styles['pages-wrapper']}>
      <SEO mark="face" />
      <Header cards={cards.slice(0, 4)} />
      <CareIntro data={categories[0]} />
      <BrandLine image={brandLine.img} />
      <Certificates />
      <CareProducts
        onShowSubcategory={showSubcategory}
        cards={face}
        subcategories={subcategories}
      />
    </div>
  );
};
export default FacePage;
