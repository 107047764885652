import Container from '../Container/Container';
import NewsItem from './NewsItem/NewsItem';
import styles from './styles.module.scss';

const News = ({ news }) => (
  <section className={styles.news}>
    <Container>
      <h2 className={styles.title}>Новости</h2>
      <div className={styles['news-wrapper']}>
        {news.map((item) => (
          <NewsItem
            key={item.id}
            title={item.title}
            pics={item.pics}
          />
        ))}
      </div>
    </Container>
  </section>
);
export default News;
