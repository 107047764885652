import Container from '../Container/Container';
import styles from './styles.module.scss';

const Certificates = () => (
  <div className={styles.certificates}>
    <Container>
      <h2 className={styles.title}>Сертификаты</h2>
      <div className={styles['certificates-wrapper']}>
        <div className={styles.logo}>
          <img src="/pic/certificate/icon-certificate.svg" alt="logo" />
        </div>
        <div className={styles['certificates-content']}>
          <h4 className={styles['certificates-title']}>
            COSMOS ORGANIC
          </h4>
          <div className={styles.descr}>
            Это международный стандарт натуральной и
            органической косметики, разработанный
            5 основными европейскими сертифицирующими группами.
          </div>
        </div>
      </div>
    </Container>
  </div>
);
export default Certificates;
