import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

const Card = ({
// eslint-disable-next-line camelcase
  img, prodName, volume, cardId, vendor_code,
}) => (
  <div className={styles.card}>
    <NavLink to={`/about/${cardId}`}>
      <div className={styles['card-image-wrapper']}>
        <div className={styles['card-avatar']}>
          <img src={img} alt="продукт" />
          {/* скидка */}
          {/* <span className={styles['prod-sale']}>15%</span> */}
        </div>
        <div className={styles['prod-about']}>
          {/* eslint-disable-next-line camelcase */}
          <span className={styles['prod-number']}>{!vendor_code ? 'Не задано' : vendor_code}</span>
          <span className={styles['prod-volume']}>{!volume ? 'Не задано' : `${volume} мл`}</span>
        </div>
      </div>
      <div className={styles['card-title']}>{prodName}</div>
    </NavLink>
  </div>
);
export default Card;
