import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import styles from './styles.module.scss';
import ProductCard from '../../components/ProductCard/ProductCard';
// import ScrollToTop from '../../utils/ScrollToTop';
// import Footer from '../../components/Footer/Footer';

const ProductCardPage = () => {
  const { cards } = useSelector((state) => state.cards);
  const { cardId } = useParams();

  let product;
  cards.forEach((card) => {
    if (card.id === +cardId) {
      product = card;
    }
  });
  if (!product) {
    return <p>Loading...</p>;
  }
  return (
    <div className={styles['product-card-page']}>
      <Header cards={cards.slice(0, 4)} />
      <ProductCard product={product} />
      {/* <Footer /> */}
    </div>
  );
};
export default ProductCardPage;
