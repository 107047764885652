import Container from '../Container/Container';
import styles from './styles.module.scss';

const Contacts = () => {
  const images = {
    img: './pic/contacts/contacts-img.png',
    tablet: './pic/contacts/contacts-img-768w.jpg',
  };
  return (
    <section className={styles.contacts}>
      <Container>
        <div className={styles['contacts-wrapper']}>
          <div className={styles['contacts-inner']}>
            <div className={styles['contacts-image']}>
              <div className={styles['contacts-image-wrapper']}>
                <picture>
                  <source srcSet={images.tablet} media="(max-width: 768px)" />
                  <img src={images.img} alt="product" />
                </picture>
              </div>
            </div>
            <div className={styles['contacts-descr']}>
              <h2 className={styles['contacts-title']}>CONTACT US</h2>
              <div className={styles['contacts-info']}>
                <div className={styles['contacts-info-item']}>
                  <span className={styles['contacts-info-item-subtitle']}>PHONE:</span>
                  <span className={styles['contacts-info-item-body']}>+7 (800) 100-5153</span>
                </div>
                <div className={styles['contacts-info-item']}>
                  <span className={styles['contacts-info-item-subtitle']}>EMAIL:</span>
                  <span className={styles['contacts-info-item-body']}>office@r-trade.org</span>
                </div>
                <div className={styles['contacts-info-item']}>
                  <span className={styles['contacts-info-item-subtitle']}>
                    Адрес
                    <br />
                    офиса:
                  </span>
                  <span className={styles['contacts-info-item-body']}>Нижегородская обл., г.о.город Нижний Новгород, ул. Ванеева,Д. 127, ПОМЕЩ. П1В ОФИС 5</span>
                </div>
                <div className={styles['contacts-info-item']}>
                  <span className={styles['contacts-info-item-subtitle']}>
                    Адрес
                    <br />
                    склада:
                  </span>
                  <span className={styles['contacts-info-item-body']}>г. Москва, ул. Рябиновая, д. 63, стр.</span>
                </div>
              </div>
              <div className={styles['contacts-social-links']}>
                <a href="/">
                  <img src="./pic/social/instagram.svg" alt="social" />
                </a>
                <a href="/">
                  <img src="./pic/social/vk.svg" alt="social" />
                </a>
                <a href="/">
                  <img src="./pic/social/dzen.svg" alt="social" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Contacts;
