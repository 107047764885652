import { Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { getCards } from './redux/store/cardSlice/cardsServices';
import ProductCardPage from './pages/ProductCardPage/ProductCardPage';
import MainPage from './pages/MainPage/MainPage';
import FacePage from './pages/FacePage/FacePage';
import BodyPage from './pages/BodyPage/BodyPage';
import HairPage from './pages/HairPage/HairPage';
import BabyPage from './pages/BabyPage/BabyPage';
import styles from './scss/styles.module.scss';
import NotFound from './pages/NotFound/NotFound';
import TanPage from './pages/TanPage/TanPage';
import ScrollToTop from './utils/ScrollToTop';

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCards());
  });

  return (
    <HelmetProvider>
      <ScrollToTop />
      <div className={styles.content}>
        <div id="modal" />
        <div id="privacy" />
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route exact path="/face" element={<FacePage />} />
          <Route exact path="/body" element={<BodyPage />} />
          <Route exact path="/tan" element={<TanPage />} />
          <Route exact path="/hair" element={<HairPage />} />
          <Route exact path="/baby" element={<BabyPage />} />
          <Route exact path="/about/:cardId" element={<ProductCardPage />} />
          {/* <Route exact path="/product" element={<MainPage />} /> */}
          {/* <Route exact path="/manufacture" element={<CertificatesPage />} /> */}
          {/* <Route exact path="/contacts" element={<MainPage />} /> */}
          <Route component={NotFound} />
        </Routes>
      </div>
    </HelmetProvider>
  );
};

export default App;
