// import Pagination from './Pagination/Pagination';
import CardsWrapper from '../../generic/Card/CardsWrapper/CardsWrapper';
import styles from './styles.module.scss';
import SelectCategory from '../../SelectCategory/SelectCategory';
import Container from '../../Container/Container';

const CareProducts = ({ cards, onShowSubcategory, subcategories }) => {
  if (cards.loading) return <p>Loading...</p>;
  return (
    <section className={styles['care-products']}>
      <Container>
        <h2 className={styles.title}>Каталог</h2>
        <SelectCategory onShowSubcategory={onShowSubcategory} subcategories={subcategories} />
      </Container>
      <CardsWrapper cards={cards} />
      <div>
        {/* <Pagination /> */ }
      </div>
    </section>
  );
};
export default CareProducts;
