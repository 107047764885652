import Modal from '../../Modal/Modal';
import styles from './styles.module.scss';

const ProductDescr = ({
  description,
  icons,
  vendorcode,
  volume,
  modalControl: [isModalOpen, setIsModalOpen],
}) => (
  <div className={styles.content}>
    <div className={styles['product-article']}>
      <span className={styles['product-number']}>{!vendorcode ? 'Не задан' : vendorcode}</span>
      <span className={styles['product-volume']}>{!volume ? '150 мл' : `${volume} мл`}</span>
    </div>
    <div className={styles.description}>
      <p>
        {description}
      </p>
    </div>
    <div className={styles.social}>
      <div className={styles['social-link']}>
        <img className={styles['img-link']} src={icons.icon1} alt="instagram" />
      </div>
      <div className={styles['social-link']}>
        <img className={styles['img-link']} src={icons.icon2} alt="vk" />
      </div>
      <div className={styles['social-link']}>
        <img className={styles['img-link']} src={icons.icon3} alt="dzen" />
      </div>
    </div>
    <div className={styles['product-btn']}>
      <div className={styles.mainBtn} onClick={() => setIsModalOpen(true)}>Свяжитесь с нами</div>
    </div>
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    />
  </div>
);
export default ProductDescr;
