// import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const NewsItem = ({ title, pics }) => (
  <div className={styles['news-item']}>
    <div className={styles['news-item-image']}>
      <picture>
        <source
          srcSet={pics.iphone}
          media="(max-width: 375px)"
        />
        <source
          srcSet={pics.mobile}
          media="(max-width: 480px)"
        />
        <source
          srcSet={pics.tablet}
          media="(max-width: 768px)"
        />
        <source
          srcSet={pics.laptop}
          media="(max-width: 1280px)"
        />
        <img src={pics.desktop} alt="Продукт" />
      </picture>
    </div>
    <div className={styles['news-item-title']}>
      {title}
    </div>
  </div>

);
export default NewsItem;
