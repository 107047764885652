import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

const SmallCard = ({ img, title, cardId }) => (
  <div className={styles.card}>
    <NavLink to={`/about/${cardId}`}>
      <div className={styles['card-image']}>
        <img src={img} alt="product" />
      </div>
    </NavLink>
    <span className={styles['card-title']}>{title}</span>
  </div>
);
export default SmallCard;
