import RatingStars from '../../RatingStars/RatingStars';
import styles from '../styles.module.scss';

const FbackSlide = ({
  name, text, date, rating,
}) => (

  <div className={styles['feedback-slide']}>
    <div className={styles['feedback-slide-inner']}>
      <h5 className={styles['feedback-name']}>{name}</h5>
      <RatingStars rating={rating} />
      <div className={styles['feedback-text']}>
        <p>
          {text}
        </p>
      </div>
      <div className={styles['feedback-date']}>
        <span>{date}</span>
      </div>
    </div>
  </div>

);
export default FbackSlide;
