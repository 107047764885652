import { createSlice } from '@reduxjs/toolkit';
import { getCards } from './cardsServices';

export const initialState = {
  cards: [],
  subcategory: [],
  loading: false,
};

export const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  extraReducers: {
    [getCards.pending]: (state) => {
      state.loading = true;
    },
    [getCards.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cards = payload;
    },
    [getCards.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export const { getSubcategory } = cardsSlice.actions;
export default cardsSlice.reducer;
