import axios from 'axios';

axios.defaults.withCredentials = true;

// const prod = process.env.NODE_ENV === 'production' ?
// process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
const prod = '/api/cards';

// console.log(instance.baseURL)
export const fetchCards = () => {
  const options = {
    method: 'GET',
    url: prod,
    headers: {
      accept: 'application/json',
    },
  };

  return axios.request(options).then((response) => {
    console.log(response);
    return response.data;
  }).catch((error) => {
    console.error(error);
  });
};
