import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { subcategoryList } from '../../utils/subcategoryList/subcategoryList';

const convertCosmeticName = (subcategory) => {
  let result = '';
  const values = Object.values(subcategoryList);
  values.forEach((el) => {
    if (el === subcategoryList[subcategory]) {
      result = el;
    }
  });
  return result;
};

const SelectCategory = ({ onShowSubcategory, subcategories }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('Выбирите Категорию');
  const rootEl = useRef(null);
  const selectCategory = (subcategory) => {
    onShowSubcategory(subcategory);
    setTitle(convertCosmeticName(subcategory));
  };
  useEffect(() => {
    const onClick = (e) => {
      if (rootEl.current !== null && !rootEl.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [rootEl]);
  return (
    <div className={styles.accordion} ref={rootEl}>
      <div className={styles['accordion-wrapper']} onClick={() => setIsOpen((p) => !p)}>
        <div className={styles['accordion-title']}>
          <span>{title}</span>
          <span className={styles.toggle} aria-expanded={isOpen}>
            <img src="/pic/arrows/select/arrow-down.png" alt="Toggle Accordion" />
          </span>
        </div>
      </div>
      <div className={styles['accordion-content']} aria-expanded={!isOpen}>
        <div className={styles['accordion-content-inner']}>
          {subcategories.map((subcat) => (
            <div
              key={subcat}
              className={`${styles['accordion-item']} ${title === convertCosmeticName(subcat) ? styles.active : null}`}
              onClick={() => selectCategory(subcat)}
            >
              {convertCosmeticName(subcat)}
              {title === convertCosmeticName(subcat) ? (
                <img src="/pic/product-card-icons/check-mark.png" alt="check mark" />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SelectCategory;
