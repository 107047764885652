import Slider from 'react-slick';
import styles from './styles.module.scss';
// Import css files
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
/* eslint-disable react/jsx-props-no-spreading */
import FbackSlide from './FbackSlide/FbackSlide';
import { ArrowLeft, ArrowRight } from './FbackArrows/FbackArrows';

const FbackSlider = ({ reviewCard }) => {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles['feedback-slider-wrapper']}>
      <Slider
        {...settings}
        className={styles['feedback-slider']}
      >
        {reviewCard.map((review) => (
          <FbackSlide
            key={review.card}
            name={review.author}
            text={review.text}
            date={review.creation_date}
            rating={review.rating}

          />
        ))}
        {/* <FbackSlide
          name="Имя Фамилия"
          text={feedback[0].feedback1.text}
        />
        <FbackSlide
          name="Имя Фамилия"
          text={feedback[0].feedback1.text}
        />
        <FbackSlide
          name="Имя Фамилия"
          text={feedback[0].feedback1.text}
        />
        <FbackSlide
          name="Имя Фамилия"
          text={feedback[0].feedback1.text}
        /> */}
        {/* {feedback.map((feedback) => (
        ))} */}
      </Slider>
    </div>
  );
};
export default FbackSlider;
