import { createSlice } from '@reduxjs/toolkit';
import { getCategories } from './categoriesService';

const initialState = {
  categories: [],
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loading = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default categoriesSlice.reducer;
