import { Link } from 'react-router-dom';
import Container from '../Container/Container';
import styles from './styles.module.scss';

const Collage = () => {
  const images = {
    pic1: '/pic/products/product_1.jpeg',
    pic2: '/pic/products/product_2.jpeg',
    pic3: '/pic/products/product_3.jpg',
    pic4: '/pic/products/product_4.jpeg',
  };
  return (
    <section className={styles.collage}>
      <Container>
        <div className={styles['collage-title']}>
          <h6 className={styles.subtitle}>ОТКРОЙТЕ ДЛЯ СЕБЯ САМЫЕ ПРОДАВАЕМЫЕ ПРОДУКТЫ</h6>
          <h2 className={styles.title}>
            <span>
              IVA NATURA
            </span>
            {' '}
            ДЛЯ КАЖДОГО
          </h2>
        </div>
        <div className={styles['collage-container']}>
          <div className={`${styles['collage-item']} ${styles.item1}`}>
            <img src={images.pic2} alt="product" />
            <Link className={styles['btn-main']} to="/face">Уход за лицом</Link>
          </div>
          <div className={`${styles['collage-item']} ${styles.item2}`}>
            <img src={images.pic1} alt="product" />
            <Link className={styles['btn-main']} to="/hair">Волосы</Link>
          </div>
          <div className={`${styles['collage-item']} ${styles.item3}`}>
            <img src={images.pic3} alt="product" />
            <Link className={styles['btn-main']} to="/baby">Для детей</Link>
          </div>
          <div className={`${styles['collage-item']} ${styles.item4}`}>
            <img src={images.pic4} alt="product" />
            <Link className={styles['btn-main']} to="/body">Уход за телом</Link>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Collage;
