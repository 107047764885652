import styles from './styles.module.scss';

const PurposeTitle = () => (
  <h2 className={styles.title}>
    <span>
      Наша главная цель
    </span>
    - создать компоненты, регенерации и защиты эффективными для
    каждого из наших продуктов в области здоровья и ухода за кожей.
  </h2>
);
export default PurposeTitle;
