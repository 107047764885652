import React from 'react';
import Slider from 'react-slick';

// Import css files
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import styles from './styles.module.scss';
import MultiSlide from './MultiSlide/MultiSlide';
import { ArrowLeft, ArrowRight } from './SliderArrows/SliderArrows';

/* eslint-disable react/jsx-props-no-spreading */
const MultiSlider = ({ cards }) => {
  if (cards.length === 0) {
    return <div>Loading...</div>;
  }
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles['footer-slider-wrapper']}>
      <Slider
        {...settings}
        className={styles['footer-slider']}
      >
        {cards.map((card) => (
          <MultiSlide key={card.id} image={card.pic} prodName={card.title} cardId={card.id} />
        ))}
      </Slider>
    </div>
  );
};

export default MultiSlider;
