import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Dropdown from './Dropdown/Dropdown';

const Header = ({ cards }) => {
  const [navbar, setNavbar] = useState(false);
  const [open, setOpen] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', changeBackground);

    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, [window.scrollY]);

  return (
    <div className={styles[`${navbar ? 'header-scroll' : 'header'}`]}>
      <div className={styles['header-wrapper']}>
        <Link className={styles['header-logo-link']} to="/">
          <img src="/logo.png" alt="logo" />
        </Link>
        <nav className={styles['header-nav']}>
          <Link className={styles['header-nav-link']} to="/face">Лицо</Link>
          <Link className={styles['header-nav-link']} to="/hair">Волосы</Link>
          <Link className={styles['header-nav-link']} to="/body">Тело</Link>
          <Link className={styles['header-nav-link']} to="/tan">Загар</Link>
          <Link className={styles['header-nav-link']} to="/baby">Дети</Link>
        </nav>
        <button type="button" className={`${styles['header-burger']} ${open && styles.active}`} onClick={() => setOpen(true)}>
          <img src="/burger.svg" alt="burger" />
        </button>
        <button type="button" className={`${styles['header-burger-close']} ${open && styles.active}`} onClick={() => setOpen(false)}>
          <img src="/burger-close.svg" alt="burger-close" />
        </button>
      </div>
      { open ? <Dropdown cards={cards} /> : null }
    </div>
  );
};

export default Header;
