import Container from '../../../Container/Container';
import Card from '../Card';
import styles from './styles.module.scss';

const CardsWrapper = ({ cards, title }) => (
  <Container>
    {title ? <h2 className={styles.title}>{title}</h2> : null}
    <div className={styles['cards-wrapper']}>
      {cards.map((card) => (
        <Card
          key={card.id}
          cardId={card.id}
          prodName={card.title}
          img={card.pic}
          volume={card.volume}
          vendor_code={card.vendor_code}
        />
      ))}
    </div>
  </Container>
);
export default CardsWrapper;
