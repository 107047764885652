import Container from '../../Container/Container';
import styles from './styles.module.scss';

const CareIntro = ({ data }) => {
  if (!data) return <div>Loading...</div>;
  const {
    title, description, pic,
  } = data;
  return (
    <section className={styles['care-intro']}>
      <Container>
        <div className={styles['care-intro-wrapper']}>
          <div className={styles['care-intro-image']}>
            <img src={pic} alt="product" />
          </div>
          <div className={styles['care-intro-descr']}>
            <div className={styles['care-intro-inner']}>
              <h6 className={styles.subtitle}>IVA NATURA</h6>
              <h2 className={styles.title}>{title}</h2>
              <div className={styles['product-text']}>
                <p>
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default CareIntro;
