import { Link } from 'react-router-dom';
import { useState } from 'react';
import Modal from '../Modal/Modal';
import styles from './styles.module.scss';
import Container from '../Container/Container';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <footer className={styles.footer}>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Container>
        <div className={styles['footer-wrapper']}>
          <Link className={styles['footer-logo-link']} to="/">
            <img src="/logo-large.png" alt="logo" />
          </Link>
          <div className={styles['footer-nav-container']}>
            <nav className={styles['footer-nav']}>
              <Link className={`${styles['footer-nav-link']} ${styles.face}`} to="/face">Лицо</Link>
              <Link className={`${styles['footer-nav-link']} ${styles.tan}`} to="/tan">Загар</Link>
              <Link className={`${styles['footer-nav-link']} ${styles.hair}`} to="/hair">Волосы</Link>
              <Link className={`${styles['footer-nav-link']} ${styles.kids}`} to="/baby">Дети</Link>
              <Link className={`${styles['footer-nav-link']} ${styles.body}`} to="/body">Тело</Link>
            </nav>
          </div>
          <div className={styles['footer-contacts']}>
            <div className={styles['btn-main']} onClick={() => setIsModalOpen(true)}>Свяжитесь с нами</div>
            <div className={styles['contacts-social-links']}>
              <a href="/">
                <img src="./pic/social/instagram.svg" alt="social" />
              </a>
              <a href="/">
                <img src="./pic/social/vk.svg" alt="social" />
              </a>
              <a href="/">
                <img src="./pic/social/dzen.svg" alt="social" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
